import { default as _404vPzf1XjGQ3Meta } from "/usr/src/app/pages/404.vue?macro=true";
import { default as accountdWl6ticfZzMeta } from "/usr/src/app/pages/account.vue?macro=true";
import { default as bundlesZvV7qeM3ZSMeta } from "/usr/src/app/pages/bundles.vue?macro=true";
import { default as cart37DfDFSHEiMeta } from "/usr/src/app/pages/cart.vue?macro=true";
import { default as indexJPEr6nErnHMeta } from "/usr/src/app/pages/checkout/index.vue?macro=true";
import { default as contactUso6OlRP2aEhMeta } from "/usr/src/app/pages/contactUs.vue?macro=true";
import { default as _91id_93mZdkyxfClcMeta } from "/usr/src/app/pages/custom/[id].vue?macro=true";
import { default as faqDkOohHQxeIMeta } from "/usr/src/app/pages/faq.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as order_45placed6k6OtQdSpLMeta } from "/usr/src/app/pages/order-placed.vue?macro=true";
import { default as contentqWkrqCQFuAMeta } from "/usr/src/app/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failedSg3MXAHmCsMeta } from "/usr/src/app/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionZ4vgdVuUrVMeta } from "/usr/src/app/pages/processing-transaction.vue?macro=true";
import { default as _91id_93BZxSEuLtmFMeta } from "/usr/src/app/pages/products/[id].vue?macro=true";
import { default as indextGTcZpyi4NMeta } from "/usr/src/app/pages/products/index.vue?macro=true";
import { default as register6aSL4CEIZgMeta } from "/usr/src/app/pages/register.vue?macro=true";
import { default as resety7s7g96HwmMeta } from "/usr/src/app/pages/reset.vue?macro=true";
import { default as staticPNyfWeeIxTMeta } from "/usr/src/app/pages/static.vue?macro=true";
import { default as tenantLoginQ32sF0kcXmMeta } from "/usr/src/app/pages/tenantLogin.vue?macro=true";
import { default as verifyRGGI4FzELVMeta } from "/usr/src/app/pages/verify.vue?macro=true";
import { default as wishlistuT8akadtc5Meta } from "/usr/src/app/pages/wishlist.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/usr/src/app/pages/404.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: accountdWl6ticfZzMeta || {},
    component: () => import("/usr/src/app/pages/account.vue")
  },
  {
    name: "bundles",
    path: "/bundles",
    component: () => import("/usr/src/app/pages/bundles.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/usr/src/app/pages/cart.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexJPEr6nErnHMeta || {},
    component: () => import("/usr/src/app/pages/checkout/index.vue")
  },
  {
    name: "contactUs",
    path: "/contactUs",
    meta: contactUso6OlRP2aEhMeta || {},
    component: () => import("/usr/src/app/pages/contactUs.vue")
  },
  {
    name: "custom-id",
    path: "/custom/:id()",
    meta: _91id_93mZdkyxfClcMeta || {},
    component: () => import("/usr/src/app/pages/custom/[id].vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/usr/src/app/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "order-placed",
    path: "/order-placed",
    component: () => import("/usr/src/app/pages/order-placed.vue")
  },
  {
    name: "pages-key-content",
    path: "/pages/:key()/content",
    component: () => import("/usr/src/app/pages/pages/[key]/content.vue")
  },
  {
    name: "payment-failed",
    path: "/payment-failed",
    component: () => import("/usr/src/app/pages/payment-failed.vue")
  },
  {
    name: "processing-transaction",
    path: "/processing-transaction",
    meta: processing_45transactionZ4vgdVuUrVMeta || {},
    component: () => import("/usr/src/app/pages/processing-transaction.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    meta: _91id_93BZxSEuLtmFMeta || {},
    component: () => import("/usr/src/app/pages/products/[id].vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indextGTcZpyi4NMeta || {},
    component: () => import("/usr/src/app/pages/products/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register6aSL4CEIZgMeta || {},
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "reset",
    path: "/reset",
    component: () => import("/usr/src/app/pages/reset.vue")
  },
  {
    name: "static",
    path: "/static",
    component: () => import("/usr/src/app/pages/static.vue")
  },
  {
    name: "tenantLogin",
    path: "/tenantLogin",
    meta: tenantLoginQ32sF0kcXmMeta || {},
    component: () => import("/usr/src/app/pages/tenantLogin.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/usr/src/app/pages/verify.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/usr/src/app/pages/wishlist.vue")
  }
]