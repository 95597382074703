import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import browser_45guard_45global from "/usr/src/app/middleware/browserGuard.global.ts";
import features_45global from "/usr/src/app/middleware/features.global.ts";
import page_45view_45tracker_45global from "/usr/src/app/middleware/pageViewTracker.global.ts";
import tenant_45auth_45global from "/usr/src/app/middleware/tenantAuth.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  browser_45guard_45global,
  features_45global,
  page_45view_45tracker_45global,
  tenant_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/src/app/middleware/auth.ts"),
  checkout: () => import("/usr/src/app/middleware/checkout.ts"),
  "processing-transaction": () => import("/usr/src/app/middleware/processing-transaction.ts")
}